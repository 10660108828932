import React from "react"
import { css } from "styled-components"

const CircleImage = ({ image, className, imageSizePercent = 50 }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 14px;
    `}
    className={className}
  >
    <div
      css={css`
        background: white;
        box-shadow: 0px 5px 11px #8888881c;
        border-radius: 50%;
        width: 100%;
        padding-top: 100%;
      `}
    />
    <img
      src={image}
      alt="logo"
      css={css`
        position: absolute;
        width: ${imageSizePercent}%;
        user-select: none;
        pointer-events: none;
      `}
    />
  </div>
)

export default CircleImage
