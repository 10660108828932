import React, { useEffect } from "react"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"

import { useIsPWA } from "../hooks/useIsPWA"

import Footer from "./Footer"
import Background from "./Background"
import Install from "./Install"

const GlobalStyle = createGlobalStyle`
  * {
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', system-ui, sans-serif;
    font-weight: 300;
    box-sizing: border-box;
    user-select: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }

  body {
    background: #ffb700;
    background-image: linear-gradient(
      to bottom,
      #ffb700,
      #ffee61 50%,
      #ffee61 100%
    );
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    padding-top: env(safe-area-inset-top);
  }

  body::-webkit-scrollbar {
    display: none;
  }
`

const Layout = ({ children }) => {
  useEffect(() => {
    window.oncontextmenu = e => {
      // android needs it
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  })

  const { isPWA } = useIsPWA()

  // if (!isPWA) {
  //   return <Install />
  // }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap"
          rel="stylesheet"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <link
          href="/splashscreen/iphone5_splash.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreen/iphone6_splash.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreen/iphoneplus_splash.png"
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreen/iphonex_splash.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreen/iphonexr_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreen/iphonexsmax_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
      </Helmet>
      <GlobalStyle />
      <Background>{children}</Background>
      <Footer />
    </>
  )
}

export default Layout
