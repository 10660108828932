import React from "react"
import { css } from "styled-components"
import { FaArrowLeft, FaCog, FaFacebook, FaInfo } from "react-icons/fa"
import { Link } from "gatsby"
import { useMatch, useNavigate } from "@reach/router"
import { motion } from "framer-motion"

import MenuButton from "../svg/menu.svg"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const BackButton = ({ children, ...rest }) => {
  const navigate = useNavigate()

  return (
    <button
      css={css`
        height: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        background: transparent;
        cursor: pointer;
        outline: none;
      `}
      onClick={() => navigate(-1)}
      {...rest}
    >
      {children}
    </button>
  )
}

const Footer = () => {
  const match = useMatch("/")

  return (
    <div
      css={css`
        position: fixed;
        bottom: 0;
        width: 100%;
        height: calc(110px + env(safe-area-inset-bottom));
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          position: absolute;
          top: 0;
          z-index: 100;
        `}
      >
        <motion.div whileTap={{ scale: 0.9 }}>
          <Link
            to="/"
            css={css`
              outline: 0;
              background: #00d9ed;
              border-radius: 50%;
              width: 76px;
              height: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              box-shadow: 0px 5px 11px #00000038;
              z-index: 100;
              position: relative;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              -webkit-touch-callout: none;
            `}
          >
            <img
              src={MenuButton}
              alt="menu"
              css={css`
                width: 100%;
                padding: 10px;
              `}
            />
          </Link>
        </motion.div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          width: 100%;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: calc(74px + env(safe-area-inset-bottom));
            background-image: radial-gradient(
              circle 50px at 50% 0,
              transparent 49px,
              white 0px
            );
            @supports not (
              background-image:
                radial-gradient(
                  circle 50px at 50% 0,
                  transparent 49px,
                  white 0px
                )
            ) {
              background: white;
            }
            border-top-left-radius: 34px;
            border-top-right-radius: 34px;
          `}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              height: 76px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8%;
              `}
            >
              <div
                css={css`
                  margin-right: 30px;
                `}
              >
                {match ? (
                  <div
                    css={css`
                      width: 30px;
                    `}
                  />
                ) : (
                  <BackButton>
                    <motion.div
                      whileTap={{ scale: 0.8 }}
                      whileFocus={{ scale: 0.8 }}
                    >
                      <FaArrowLeft size="30px" color="#BBBBBB" />
                    </motion.div>
                  </BackButton>
                )}
              </div>
              <OutboundLink
                href="https://www.facebook.com/soproniegyetem/"
                target="_blank"
                rel="noreferrer noopener"
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  whileFocus={{ scale: 0.8 }}
                >
                  <FaFacebook size="30px" color="#BBBBBB" />
                </motion.div>
              </OutboundLink>
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8%;
              `}
            >
              <Link
                to="/settings"
                css={css`
                  margin-right: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  whileFocus={{ scale: 0.9 }}
                >
                  <FaCog size="30px" color="#BBBBBB" />
                </motion.div>
              </Link>
              <Link
                to="/info"
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  whileFocus={{ scale: 0.9 }}
                >
                  <FaInfo size="30px" color="#BBBBBB" />
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
