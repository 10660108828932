import React from "react"
import { css } from "styled-components"

const Install = () => (
  <div
    css={css`
      position: fixed;
      top: 50%;
      background: red;
      z-index: 100;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      padding: 20px;
      border-radius: 23px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  >
    please install
  </div>
)

export default Install
