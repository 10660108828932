import styled from "styled-components"

const Container = styled.div`
  flex: 1 1 auto;
  background: #eef4f6;
  border-top-left-radius: 34px;
  border-top-right-radius: 34px;
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  display: flex;
  flex-direction: column;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`

export default Container
