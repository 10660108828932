import React from "react"
import { css } from "styled-components"

import BackgroundImg from "../svg/background.svg"

const Background = ({ children }) => (
  <div
    css={css`
      background: url(${BackgroundImg});
      min-height: 100vh;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      background-size: 100%;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
    `}
  >
    {children}
  </div>
)

export default Background
