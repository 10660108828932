import { useEffect, useState } from "react"

export const useIsPWA = () => {
  const [isPWA, setPWA] = useState()

  useEffect(() => {
    const installPrompt = e => {
      console.log(e, "install")
      // e.preventDefault()
    }
    window.addEventListener("beforeinstallprompt", installPrompt)

    const matches = matchMedia("(display-mode: fullscreen)").matches
    if (matches) {
      setPWA(true)
    } else if ("fullscreen" in navigator) {
      setPWA(navigator.fullscreen)
    }

    return () =>
      window.removeEventListener("beforeinstallprompt", installPrompt)
  }, [])

  return { isPWA }
}
